li {
    cursor: pointer;
    padding: 10px;
  }
  
  li.active {
    background-color: #dc8e52;
    color: rgb(0, 0, 0);
  }
  
  li:hover {
    background-color: #dc8e52 !important;
    color: black;
  }