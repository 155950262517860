.device-monitor-page {
    padding: 40px 100px;
}

.graphs {
    width: 800px;
    height: 400px;
}

.back-button {
    background-color: #f18305;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9em;
    margin-bottom: 10px;
}

.parent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}