.device-card-grid {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    flex-wrap: wrap;
    gap: 50px;
    padding: 10px;
}

.device-card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.device-card h4 {
    margin: 0 0 10px;
    font-size: 1.2em;
}

.device-card p {
    margin: 5px 0;
    font-size: 0.9em;
}

.card-buttons {
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.card-buttons button {
    background-color: #f18305;
    color: #fff;
    border: none;
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9em;
}

.card-buttons button:hover {
    background-color: #b15102;
}

 
 .capabilities-table {

    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.capabilities-table th,
.capabilities-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.capabilities-table th {
    background-color: #efefef;
    font-weight: bold;
}

.new-limit-input {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.update-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.update-button {
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.update-button:hover {
    background-color: #0056b3;
}

.device-status {
    padding: 5px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
}

.device-status.online {
    background-color: green;
    color: white;
}

.device-status.offline {
    background-color: red;
    color: white;
}

.dashboard-container {
    display: flex;
    height: 100vh;
}

.sidebar {
    width: 370px;
    background-color: #f4f4f4;
    padding: 20px;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
}

.sidebar li {
    margin: 10px 0;
    color: white;
    cursor: pointer;
    padding: 10px;
    background-color: #f18305;
    text-align: center;
    border-radius: 5px;
}

.sidebar li:hover {
    background-color: #b15102 !important;
    color: white;

}

.sidebar li:active {
    background-color: #b15102 !important;
    color: white;

}

.main-content {
    flex-grow: 1;
    padding: 20px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    /* Centers the grid horizontally */
    align-items: flex-start;
    /* Align grid at the top */
}

.heading {
    text-align: center;
    padding: 20px;
}

.highlight-row {
    background-color: rgb(251, 100, 87);
}

.logout-button {
    padding: 5px 20px;
    font-size: 13px;
    background-color: black;
    color: white;
    border-radius: 5px;
}

.check-status-button {
    background-color: #f18305;
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9em;
}

button[type="limit_button"][value="update"] {
    color: black
}

.onlineBtn {
    background-color: #0b841f;
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9em;
}

.offlineBtn {
    background-color: #f13405;
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9em;
}

.container-right {
    display: flex;
    flex-direction: column;
}

.parent {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.showBtn {
    margin-left: 8px;
}