.device-search {
    padding: 10px;
    width: 90%;
    align-self: center;
    margin: 10px auto;
}

.logs-container {
    margin: 20px auto;
}

.logs-container h2 {
    margin-top: 20px;
}

.logs-container ul {
    list-style-type: none;
    padding: 20px;
    max-height: 500px;
    overflow-y: auto;
    border: 1px solid #ddd;
    padding: 20px;
    background-color: #f9f9f9;
}

.logs-container ul li {
    padding: 5px;
    border-bottom: 1px solid #eee;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 40px;
}

.pagination button {
    padding: 5px 10px;
    background-color: #efbb11;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.pagination button:hover {
    background-color: #ecb709;
}

.pagination span {
    font-size: 14px;
    font-weight: bold;
}

.fetchBtn {
    background-color: #f18305;
    color: #fff;
    border: none;
    padding: 1px 30px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9em;
    margin-top: 20px;
}

.time-range-selector {
    margin-top: 40px;
    display: flex;
    gap: 30px;
}

.listCard {
    border: 2px solid black;
    gap: 20px;
    border-radius: 3px;
    display: flex;
    gap: 30px;
}

table {
    width: 90vw;
}

th {
    cursor: pointer;
}

td,
th {
    padding: 8px;
    text-align: center;
}

table,
th,
td {
    border: 1px solid black;
    border-collapse: separate;
}

.select {
    padding: 4px;
    margin-left: 7px;
    background-color: #ffc37e;
    color: black;
    border: none;
    outline: none;
    border-radius: 3px;
}

.limits {
    display: flex;
    gap: 1px;
    justify-content: center;
    align-items: center;
}

.limits button {
    background-color: white;
    border: none;
    margin-left: 15px;
    cursor: pointer;
}