/* Center the form on the page */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f9;
}

/* The box that contains the login form */
.login-box {
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 350px;
    text-align: center;
}

/* Headings */
h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

/* Error message styling */
.error-message {
    color: red;
    margin-bottom: 15px;
}

/* Form groups (email/password inputs) */
.form-group {
    margin-bottom: 20px;
    text-align: left;
}

/* Input fields */
input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-top: 5px;
    box-sizing: border-box;
    font-size: 16px;
    background-color: #fafafa;
    transition: border 0.2s ease;
}

/* Input focus effect */
input:focus {
    border-color: #007bff;
    outline: none;
}

/* Submit button */
.login-button {
    width: 100%;
    padding: 12px;
    background-color: #d2b71d;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.login-button:hover {
    background-color: #c4921c;
}
